@import '../../../../scss/theme-bootstrap';

.sign-in-overlay {
  color: $color-black;
  width: 374px;
  a {
    color: $color-black;
  }
  &__login,
  &__registration {
    a {
      .utility-nav .utility-nav__item & {
        text-decoration: underline;
      }
    }
  }
  &__login__container {
    @include swap_direction(margin, 22px 28px 20px 24px);
  }
  &__login-header,
  &__registration-header {
    p {
      font-size: 34px;
      line-height: get-line-height(34px, 36px);
    }
  }
  &__no-account {
    font-size: 14px;
    line-height: get-line-height(14px, 16px);
  }
  &__link {
    text-transform: none;
    &:hover {
      color: $color-dark-pink;
    }
  }
  &__input {
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      @include swap_direction(margin, 10px 0 15px);
      border: 0;
      border-bottom: 1px solid $color-light-gray;
      border-radius: 0;
      color: $cr19-text-dark-gray;
      display: block;
      float: none;
      font-size: 14px;
      height: 40px;
      letter-spacing: -0.05em;
      max-width: none;
      padding: 0;
      text-transform: uppercase;
      vertical-align: middle;
      width: 100%;
    }
    input[type='submit'] {
      @include swap_direction(margin, 25px 0 15px);
      background-image: none;
      height: 40px;
      width: 100%;
    }
    input[type='password'],
    input[type='text'] {
      text-transform: none;
    }
  }
  &__loyalty-link {
    text-transform: none;
    &:hover {
      color: $color-dark-pink;
    }
  }
  &__email {
    width: 100%;
    margin-bottom: 10px;
  }
  &__password {
    width: 100%;
    margin-bottom: 18px;
  }
  &__forgot-password {
    display: inline-block;
    margin-bottom: 20px;
  }
  &__submit {
    background-image: none;
    width: 100%;
  }
  &__registration {
    display: none;
  }
  &__login-container,
  &__registration-container {
    @include swap_direction(margin, 22px 28px 20px 24px);
  }
  &__reg-password-container {
    .sign-in-overlay__registration-password {
      float: #{$ldirection};
    }
  }
  &__registration-show-pass {
    margin: 15px 0 10px;
    display: block;
    float: #{$ldirection};
  }
  &__registration-pass-text {
    letter-spacing: 0em;
  }
  &__registration-terms,
  &__registration-email-list {
    line-height: get-line-height(13px, 18px);
    display: block;
  }
  &__reg-terms-text {
    padding-#{$ldirection}: 20px;
    position: relative;
    display: block;
    &::before {
      #{$ldirection}: 0;
      position: absolute;
      top: 4px;
    }
  }
  &__registration-email-list {
    @include swap_direction(margin, 10px 0 18px 0);
  }
  &__have-account {
    text-transform: none;
    color: $color-white;
    margin-bottom: 18px;
  }
  &__gdpr-label {
    @include swap_direction(margin, 0 0 20px);
    cursor: pointer;
    text-align: #{$ldirection};
    text-decoration: underline;
    width: 100%;
    @include breakpoint($large-up) {
      margin: 0;
      padding-top: 15px;
    }
    &:hover > .sign-in-overlay__gdpr-overlay {
      display: block;
      z-index: 99;
    }
    .sign-in-overlay__gdpr-overlay {
      background-color: $color-white;
      border-radius: 5px;
      border: 1px solid $color-black;
      bottom: -20px;
      color: $color-black;
      display: none;
      float: #{$ldirection};
      #{$ldirection}: 24px;
      padding: 15px;
      position: absolute;
      text-align: #{$ldirection};
      width: 300px;
      @include breakpoint($large-up) {
        width: 322px;
      }
      a {
        color: $color-black;
        display: inline;
        text-decoration: underline;
        &:hover {
          color: $color-black;
        }
      }
      p {
        margin: 0;
      }
    }
  }
  &__account-links {
    margin: 50px 30px;
    display: none;
    .sign-in-overlay__welcome {
      text-transform: none;
      margin-bottom: 10px;
    }
    .sign-in-overlay__link {
      letter-spacing: 1px;
      line-height: get-line-height(14px, 39px);
      height: 41px;
      border-bottom: 2px solid $color-light-gray;
    }
    .sign-in-overlay__loyalty-link {
      letter-spacing: 1px;
      line-height: get-line-height(14px, 30px);
      border-bottom: 2px solid $color-light-gray;
    }
  }
}

.sign-in {
  width: 374px;
  color: $color-black;
  a {
    color: $color-black;
  }
  p {
    margin-bottom: 10px;
  }
  .login {
    &__container {
      @include swap_direction(margin, 22px 28px 20px 24px);
    }
    &__header {
      @include h9;
      text-transform: none;
      margin-bottom: 18px;
    }
    &__email {
      width: 100%;
      margin-bottom: 10px;
    }
    &__password {
      width: 100%;
      margin-bottom: 18px;
    }
    &__forgot-password {
      font-family: $main-font;
      font-size: 14px;
      color: $color-black;
      line-height: get-line-height(14px, 18px);
      text-decoration: none;
      border-bottom: 1px solid $color-black;
      &:hover {
        text-decoration: none;
      }
      display: inline-block;
      margin-bottom: 20px;
    }
    &__submit {
      width: 100%;
    }
    &__registration-alt {
      background: $color-black;
      @include swap_direction(padding, 18px 28px 25px 24px);
    }
    &__no-account {
      @include h9;
      text-transform: none;
      color: $color-white;
      margin-bottom: 18px;
    }
    &__register {
      @include elc-button--inverted;
      line-height: normal;
      @include swap_direction(padding, 5px 27px 6px 13px);
      width: 100%;
    }
  }
  .registration {
    display: none;
    &__container {
      @include swap_direction(margin, 22px 28px 20px 24px);
    }
    &__header {
      @include h9;
      text-transform: none;
      margin-bottom: 18px;
    }
    &__email {
      width: 188px;
      margin-bottom: 10px;
    }
    &__password {
      width: 188px;
      margin-bottom: 18px;
    }
    &__password-container {
      @include clearfix;
      .registration__password {
        float: $ldirection;
      }
    }
    &__show-pass {
      display: block;
      @include swap_direction(margin, 5px 0 0 10px);
      float: $ldirection;
    }
    &__show-pass-text {
      letter-spacing: 0em;
    }
    &__terms,
    &__email-list {
      line-height: get-line-height(13px, 18px);
      display: block;
    }
    &__terms-text {
      position: relative;
      display: block;
      padding-#{$ldirection}: 20px;
      &::before {
        position: absolute;
        top: 4px;
        #{$ldirection}: 0;
      }
    }
    &__email-list {
      margin: 10px 0 18px;
    }
    &__submit {
      width: 100%;
      height: 34px;
      line-height: get-line-height(14px, 34px);
    }
    &__signin-alt {
      background: $color-black;
      @include swap_direction(padding, 18px 28px 25px 24px);
    }
    &__have-account {
      @include h9;
      text-transform: none;
      color: $color-white;
      margin-bottom: 18px;
    }
    &__login {
      @include elc-button--inverted;
      width: 100%;
      height: 34px;
      line-height: get-line-height(14px, 34px);
      padding: 0;
    }
  }
  .account-links {
    display: none;
    margin: 23px 25px 10px;
    &__welcome {
      @include h9;
      text-transform: none;
      margin-bottom: 24px;
    }
    &__profile,
    &__orders,
    &__favorites,
    &__loyalty,
    &__login,
    &__logout {
      @include h8;
      line-height: get-line-height(12px, 39px);
      height: 41px;
      border-bottom: 2px solid $color-gray-light;
    }
    &__login,
    &__logout {
      border-bottom: none;
    }
  }
  &.is_signed_in {
    width: 200px;
  }
  &-page {
    .account__return-user {
      input[type='email'] {
        width: 100%;
      }
    }
  }
}

.password-field {
  &__rules {
    column-count: 2;
    padding: 10px;
    font-size: 0.8em;
    list-style: none;
    > li {
      display: flex;
      align-items: center;
      padding: 3px 0;
      color: $color-black;
      transition: 0.2s;
      white-space: nowrap;
      &::before {
        @include swap_direction(margin, 0 6px 0 0);
        content: $cross-mark;
        display: inline-block;
        color: $color-red;
        font-size: 1em;
        line-height: 0;
        transition: 0.2s;
        text-shadow: 0 0 2px $color-black;
      }
      &.pass {
        color: $color-lighter-green;
        &::before {
          color: $color-lighter-green;
          content: $check-mark;
          text-shadow: 0 0 1px $color-black;
        }
      }
    }
    @include breakpoint($medium-up) {
      border: 1px solid $color-black;
      background-color: $color-white;
      .gnav-util__content-inner & {
        font-size: 0.9em;
      }
    }
  }
}
